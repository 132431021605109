<template>
  <div>
    <Title
      :pages="[
        { icon: 'desktop', page: 'Monitoreo' },
        { icon: '', page: page_title.toUpperCase() + ruta.numTrip },
      ]"
    ></Title>

    <v-container fluid>
      <div class="col-md-12 mt-5">
        <div class="row">
          <div class="col-md-6 p-10">
            <div class="box-monitoring-mother">
              <div class="row">
                <div class="col box-monitoring divider">
                  <h3 style="margin-top: 3px;">{{ $t("Ruta") }}</h3>
                  <h3 style="margin-top:0px">#{{ ruta.numTrip }}</h3>
                </div>
                <div class="col box-monitoring divider">
                  <h3>
                    {{ ruta.driver != null ? ruta.driver.nameDriver : "" }}
                  </h3>
                  <span style="text-transform: capitalize;"> {{ ruta.driver != null ? (ruta.driver == "null" ? "" : ruta.driver.driverTelephone) : "" }}</span>
                </div>
                <div class="col box-monitoring">
                  <p style="font-size: 16px;">
                    <b v-if="sinRemitos()" class="state_en_espera">{{ $t("En_espera") }}</b>
                    <b v-else-if="isEnCurso()" class="state_en_camino">{{ $t("En_curso") }}</b>
                    <b v-else-if="isEnEspera()" class="state_en_espera">{{ $t("En_espera") }}</b>
                    <b v-if="isComplete()" class="state_entregado">{{ $t("Completada") }}</b>
                  </p>
                  <p style="font-size: 16px;"></p>
                  <b v-if="isComplete()">
                    {{ new Date(ruta.finishedTrip).toISOString().substr(0, 10) + " - " + getHora(new Date(ruta.finishedTrip)) }}
                  </b>
                  <b v-if="isEnCurso()">
                    {{ new Date(ruta.initialDate).toISOString().substr(0, 10) + " - " + getHora(new Date(ruta.initialDate)) }}
                  </b>
                  <b v-if="isEnEspera()">
                    {{ new Date(ruta.date).toISOString().substr(0, 10) + " - " + getHora(new Date(ruta.date)) }}
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 p-10">
            <div class="box-monitoring-mother">
              <div class="row">
                <div class="col-md-3 box-monitoring divider">
                  <p>{{ $t("Remitos") }}</p>
                  <div>
                    {{ ruta.driver != null ? ruta.delivered.length : 0 }}
                  </div>
                </div>
                <div class="col-md-3 divider ">
                  <div id="chart">
                    <apexchart ref="Chart01" style="margin: 0 auto;width:89%;margin-top:8px" type="donut" width="110" height="110" :options="chart01Options" :series="series01" />
                  </div>
                </div>
                <div class="col-md-6">
                  <table class="table table-sm mb-0 table-noborder" style="margin-top:8px">
                    <tbody>
                      <tr>
                        <td><div class="circle_entregado"></div></td>
                        <td>{{ $t("Entregados") }}</td>
                        <td>
                          <b>
                            {{ ruta.delivered.length != 0 ? ((getEntregados(ruta) * 100) / ruta.delivered.length).toFixed(2) : 0 }}
                            %</b
                          >
                        </td>
                      </tr>
                      <tr>
                        <td><div class="circle_no_entregado"></div></td>
                        <td>{{ $t("No_entregados") }}</td>
                        <td>
                          <b>{{ ruta.delivered.length != 0 ? ((getNoEntregados(ruta) * 100) / ruta.delivered.length).toFixed(2) : 0 }} %</b>
                        </td>
                      </tr>
                      <tr v-if="isEnEspera()">
                        <td><div class="circle_despachado"></div></td>
                        <td>{{ $t("Despachado") }}</td>
                        <td>
                          <b>{{ ruta.delivered.length != 0 ? ((getEnCamino(ruta) * 100) / ruta.delivered.length).toFixed(2) : 0 }} %</b>
                        </td>
                      </tr>
                      <tr v-else>
                        <td><div class="circle_en_camino"></div></td>
                        <td>{{ $t("En_camino") }}</td>
                        <td>
                          <b>{{ ruta.delivered.length != 0 ? ((getEnCamino(ruta) * 100) / ruta.delivered.length).toFixed(2) : 0 }} %</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <div class="col p-10 pr-0 ">
            <div id="rutaMonitoreo">
              <div v-for="remito in ruta.delivered" :key="remito.id">
                <remitoMonitoring :assigned="remito" :ruta="ruta"></remitoMonitoring>
              </div>
            </div>
          </div>

          <div class="col p-10 pl-0 ">
            <div id="mapa">
              <GeoMultipleMarkers :remitos="ruta.delivered" :driver="ruta.driver" :ruta="ruta" />
            </div>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import Title from "@/components/Title.vue";
import remitoMonitoring from "./remitoMonitoring";
import GeoMultipleMarkers from "./GeoMultipleMarkers.vue";
import { rutasService } from "@/libs/ws/rutasService";
export default {
  components: { Title, remitoMonitoring, GeoMultipleMarkers },
  data() {
    return {
      page_title: this.$t("Ruta") + " #",
      series01: [],
      driver: [],
      chart02Options: {},
      chart01Options: {
        stroke: { show: false },
        colors: ["#f23d4f", "#d4d4d4"],
        legend: { show: false },
        tooltip: { enabled: false },
        dataLabels: { enabled: false },
        plotOptions: {
          pie: {
            customScale: 1,
            donut: {
              size: "85%",
              labels: {
                show: true,
                name: {
                  offsetY: -20,
                },
                value: {
                  fontSize: 16,
                  offsetY: -10,
                  color: "#f23d4f",
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "",
                  formatter: function(w) {
                    var result = 0;
                    if (w.globals.series[0] + w.globals.series[1] == 0) {
                      result = 0;
                    } else {
                      result = Math.trunc((w.globals.series[0] * 100) / (w.globals.series[0] + w.globals.series[1]));
                    }
                    return result + "%";
                  },
                },
              },
            },
          },
        },
      },
      remitos: [],
      ruta: [],
      flag: false,
    };
  },
  mounted() {
    this.loadRuta(this.$route.params.rutaid);

    this.updateEvery();
  },
  beforeDestroy: function() {
    clearInterval(this.interval);
  },
  watch: {
    rutaObject() {
      this.series01 = [parseInt(this.getEntregados(this.ruta)) + parseInt(this.getNoEntregados(this.ruta)), parseInt(this.getEnCamino(this.ruta))];
    },
  },
  computed: {
    rutaObject() {
      return this.ruta;
    },
  },
  methods: {
    updateEvery() {
      this.interval = setInterval(
        function() {
          this.loadRuta(this.$route.params.rutaid);
        }.bind(this),
        60000 * 5
      );
    },
    loadRuta(ruta) {
      rutasService
        .getRuta(ruta)
        .then((response) => {
          this.ruta = response;
          var aux = Object.assign({}, this.chart01Options);
          aux.colors = ["#d4d4d4", "#d4d4d4"];
          aux.plotOptions.pie.donut.labels.value.color = "#d4d4d4";
          this.chart02Options = Object.assign({}, aux);
          this.series01 = [parseInt(this.getEntregados(this.ruta)) + parseInt(this.getNoEntregados(this.ruta)), parseInt(this.getEnCamino(this.ruta))];
        })
        .catch((e) => {
          console.log("service.load error");
          console.log(e);
        });
    },

    getEntregados(ruta) {
      var contador = 0;
      if (ruta.delivered.length == 0) {
        return 0;
      }
      ruta.delivered.forEach(function(elemento) {
        if (elemento.closed && elemento.delivered != null) {
          contador++;
        }
      });
      return contador;
    },
    getNoEntregados(ruta) {
      var contador = 0;
      if (ruta.delivered.length == 0) {
        return 0;
      }
      ruta.delivered.forEach(function(elemento) {
        if (elemento.closed == true && elemento.delivered == null) {
          contador++;
        }
      });
      return contador;
    },
    getEnCamino(ruta) {
      var contador = 0;
      if (ruta.delivered.length == 0) {
        return 0;
      }
      ruta.delivered.forEach(function(elemento) {
        if (elemento.closed != true) {
          contador++;
        }
      });
      return contador;
    },
    isComplete() {
      //if (this.ruta.delivered.length == this.getEntregados(this.ruta)) {
      if (this.ruta.finishedTrip != null) {
        return true;
      } else {
        return false;
      }
    },
    isEnCurso() {
      if (!this.isComplete() && this.ruta.initialDate != null) {
        return true;
      } else {
        return false;
      }
    },
    isEnEspera() {
      if (!this.isComplete() && this.ruta.initialDate == null) {
        return true;
      } else {
        return false;
      }
    },
    sinRemitos() {
      if (this.ruta != null) {
        if (this.ruta.delivered.length == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    getHora(date) {
      const tempMinutes = date.getMinutes();
      const minutes = tempMinutes < 10 ? `0${tempMinutes}` : tempMinutes;
      var hora = date.getHours() + ":" + minutes;
      return hora;
    },
  },
};
</script>
